import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import useScreenRecognition from 'hooks/useScreenRecognition';

import Branded from 'components/common/Branded';
import UniversalImage from 'components/common/UniversalImage';
import WhyNurofenTitle from 'components/WhyNurofen/WhyNurofenTitle';

import { WhyNurofenProps } from './model';
import './WhyNurofen.scss';

const WhyNurofen: FC<WhyNurofenProps> = ({
  brands,
  titleColor,
  bgColor,
  title,
  content,
  contentBottom,
  mainImg,
  mainImgAlt,
  listDotColor,
  hideImageOnMobile,
}) => {
  const { isMobile } = useScreenRecognition();

  const bgColorApplied = bgColor?.[0]?.properties.colorPicker?.label
    ? `${bgColor[0].properties.colorPicker.label}-default-bg`
    : '';
  const blockClasses = classNames('nf-why-nurofen', {
    [bgColorApplied]: bgColorApplied,
  });

  const titleColorApplied = titleColor?.[0]?.properties.colorPicker?.label
    ? `${titleColor[0].properties.colorPicker.label}-default-text`
    : 'navy-default-text';
  const titleClasses = classNames('nf-why-nurofen__title', {
    [titleColorApplied]: titleColorApplied,
  });

  const listDotColorApplied = listDotColor?.[0]?.properties.colorPicker?.label
    ? `${listDotColor[0].properties.colorPicker.label}-default-bg`
    : '';
  const listDotClasses = classNames('nf-why-nurofen__content', {
    [listDotColorApplied]: listDotColorApplied,
  });

  return title || content ? (
    <div data-test="WhyNurofen" className={blockClasses}>
      {mainImg ? (
        isMobile && hideImageOnMobile ? null : (
          <div className="nf-why-nurofen__image-frame">
            {title ? <WhyNurofenTitle classes={titleClasses} title={title} /> : null}
            <UniversalImage
              imageAlt={mainImgAlt}
              img={mainImg}
              wrapperClassName="nf-why-nurofen__img"
              objectFitData={{ objectFit: 'contain' }}
            />
          </div>
        )
      ) : null}
      <Container fluid>
        <Row>
          <Col lg={mainImg ? { span: 9 } : { span: 8, offset: 3 }}>
            {title ? <WhyNurofenTitle classes={titleClasses} title={title} /> : null}
            {content ? (
              <div className={listDotClasses} dangerouslySetInnerHTML={{ __html: content }} />
            ) : null}
            {brands?.length ? <Branded brands={brands} /> : null}
            {contentBottom ? (
              <div className={listDotClasses} dangerouslySetInnerHTML={{ __html: contentBottom }} />
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentWhyNurofen on IWhyNurofen {
    description
    descriptionBottom
    mainImg {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 1200, fit: CONTAIN, background: "rgba(0,0,0,0)") {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileImage: gatsbyImage {
        childImageSharp {
          fluid(maxHeight: 300, fit: CONTAIN, background: "rgba(0,0,0,0)") {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    infographics {
      properties {
        text
        icon {
          fallbackUrl
          url
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          svg {
            content
          }
        }
      }
    }
    title
    infoBannerImageAlt
    infoBannerBulletColor {
      properties {
        colorPicker {
          label
        }
      }
    }
    infoBannerTitleTextColor {
      properties {
        colorPicker {
          label
        }
      }
    }
    infoBannerBgColor {
      properties {
        colorPicker {
          label
        }
      }
    }
    infoBannerHideImageOnMobile
    submenuLink {
      properties {
        submenuItemAnchor
        submenuItemName
      }
    }
  }
`;

export default WhyNurofen;
